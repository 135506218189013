import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled, { css } from "styled-components"
import { FcQuestions } from "react-icons/fc"
import { BiRightArrowAlt } from "react-icons/bi"

import { pagePath, externalLinkPath } from "../../constants/link"
import { media } from "../../styles/media"

import HeaderNonVisual from "../../components/HeaderNonVisual"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

const FAQdiv = styled.div`
  padding: 50px 5%;
  h4 {
    font-size: 1.32em;
    margin-bottom: 20px;
  }
  .faqSection {
    padding: 30px 0;
    h5 {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-bottom: 0.62em;
      font-weight: bold;
    }
    .answerSection {
      p {
        padding: 0.62em 0;
      }
      table {
        border: 1px solid #dadada;
        margin-top: 20px;
        tr th,
        td {
          padding: 0.32em 1.62em;
          border: 1px solid #dadada;
          text-align: center;
        }
        tr th {
          background: #fafafa;
        }
        tr td {
          font-size: 0.9em;
        }
      }
    }
  }
`

const BottomLinks = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  a {
    background: ${({ theme }) => theme.colors.primary.brown};
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0.62em 1.62em;
    border-radius: 5px;
    color: white;
    ${() =>
      media.sp(css`
        margin-bottom: 15px;
      `)}
    svg {
      margin-left: 0.62em;
    }
  }
`

const FaqPage = () => {
  const data = useStaticQuery(query)
  const { strapiFaqPage: cats } = data
  return (
    <Layout>
      <SEO>
        <title>よくあるご質問｜フランソア</title>
      </SEO>
      <HeaderNonVisual
        textEn="FAQ"
        textJp={cats.faqTitle}
        textMain={cats.faqLeadText}
      />
      <FAQdiv>
        <h4>{cats.faqBlock.faqBlockTitle}</h4>
        {cats.faqBlock.faqTItles.map((dog, index) => (
          <div className="faqSection" key={index}>
            <h5>
              <FcQuestions />
              {dog.questions}
            </h5>
            <div
              className="answerSection"
              dangerouslySetInnerHTML={{ __html: dog.answers }}
            />
          </div>
        ))}
      </FAQdiv>
      <BottomLinks>
        <Link to={pagePath.inquiry.path}>
          お問合せに戻る
          <BiRightArrowAlt />
        </Link>
        <a
          href={externalLinkPath.corporateHome.path}
          target="_blank"
          rel="noopener noreferrer"
        >
          会社に関するお問合せ
          <BiRightArrowAlt />
        </a>
      </BottomLinks>
    </Layout>
  )
}

export const query = graphql`
  {
    strapiFaqPage {
      faqTitle
      faqLeadText
      faqBlock {
        faqBlockTitle
        faqTItles {
          id
          answers
          questions
        }
      }
    }
  }
`

export default FaqPage
